import autosize from 'autosize';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import type {
  ChangeEvent,
  FC,
  MouseEventHandler,
  MutableRefObject,
} from 'react';

import AttentionCircleIcon from '../icons/AttentionCircleIcon';
import { validEnum } from '../utils';
import type { AnyObject } from '../utils';

import s from './TextInput.module.scss';

export enum TextInputAutocomplete {
  Off = 'off',
  On = 'on',
  Email = 'email',
  FamilyName = 'family-name', // Surname
  GivenName = 'given-name', // First name
  Name = 'name',
  Username = 'username',
  NewPassword = 'new-password',
  CurrentPassword = 'current-password',
  OneTimeCode = 'one-time-code',
}

export enum TextInputType {
  Email = 'email',
  Text = 'text',
  Password = 'password',
}

type Props = {
  autoComplete?: TextInputAutocomplete;
  classNames?: {
    root?: string;
    inputContainer?: string;
    input?: string;
  };
  disabled?: boolean;
  error?: boolean;
  helpText?: string;
  inputRef?: MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | undefined
  >;
  maxLength?: number;
  multiline?: boolean;
  name: string;
  onChange?: (event: ChangeEvent) => boolean;
  onClick?: MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  pattern?: string;
  placeholder: string;
  showCharsLeft?: boolean;
  showErrorIcon?: boolean;
  style?: AnyObject;
  tabIndex?: number;
  type?: TextInputType;
  value?: string;
};

const TextInput: FC<Props> = ({
  autoComplete = TextInputAutocomplete.Off,
  classNames = {
    root: '',
    inputContainer: '',
    input: '',
  },
  disabled,
  error,
  helpText,
  inputRef,
  maxLength,
  multiline,
  name,
  onChange,
  onClick,
  pattern,
  placeholder,
  showCharsLeft = false,
  showErrorIcon = false,
  style,
  tabIndex,
  type = TextInputType.Text,
  value,
}) => {
  const internalInputRef = inputRef;
  const [internalValue, setInternalValue] = useState(value);

  if (!validEnum(TextInputAutocomplete, autoComplete)) {
    autoComplete = TextInputAutocomplete.Off;
  }

  useEffect(() => {
    if (multiline && internalInputRef?.current) {
      autosize(internalInputRef.current);
    }
  }, [internalInputRef]);

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  const _onChange = (event: ChangeEvent) => {
    let shouldSetValue;

    if (typeof onChange === 'function') {
      shouldSetValue = onChange(event);
    }

    if (shouldSetValue) {
      setInternalValue(
        (event.target as HTMLInputElement | HTMLTextAreaElement).value
      );
    }
  };

  let charsLeft = 0;

  if (showCharsLeft) {
    charsLeft = maxLength - internalValue?.length;
  }

  return (
    <div
      className={cn(
        s.root,
        disabled && s.disabled,
        multiline && s.multiline,
        internalValue && s.hasValue,
        showErrorIcon && error && s.hasRightIcon,
        error && s.error,
        classNames.root
      )}
      style={style}>
      <div className={s.inputOuterContainer}>
        <div className={s.placeholderContainer}>
          <div className={s.placeholder}>{placeholder}</div>
        </div>
        <div className={cn(s.inputContainer, classNames.inputContainer)}>
          {multiline ? (
            <textarea
              className={cn(s.input, classNames.input)}
              name={name}
              onChange={_onChange}
              disabled={disabled}
              ref={internalInputRef as MutableRefObject<HTMLTextAreaElement>}
              onClick={onClick}
              tabIndex={tabIndex}
              value={internalValue || ''}
            />
          ) : (
            <>
              <input
                className={cn(s.input, classNames?.input)}
                type={type}
                name={name}
                onChange={_onChange}
                value={internalValue || ''}
                autoComplete={autoComplete}
                disabled={disabled}
                ref={inputRef as MutableRefObject<HTMLInputElement>}
                onClick={onClick}
                pattern={pattern}
                maxLength={maxLength}
                tabIndex={tabIndex}
              />
              <div className={s.rightIcons}>
                {showErrorIcon && error && (
                  <div className={s.rightIcon}>
                    <AttentionCircleIcon className={s.svgIcon} />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {helpText && <div className={s.helpText}>{helpText}</div>}
      {showCharsLeft && (charsLeft < 0 || charsLeft > 0) && (
        <div className={cn(s.charsLeft, charsLeft <= 40 && s.charsLeftDanger)}>
          {charsLeft < 0 ? (
            <>Too long. Remove {Math.abs(charsLeft)} chars</>
          ) : (
            charsLeft > 0 && <>{charsLeft} characters left</>
          )}
        </div>
      )}
    </div>
  );
};

export default TextInput;
