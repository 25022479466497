import cn from 'classnames';
import React from 'react';
import type { FC, FormEvent } from 'react';

import { AnyObject } from '../utils';

import s from './Form.module.scss';

type Props = {
  classNames?: {
    root?: string;
  };
  noValidate?: boolean;
  onSubmit?: (event: FormEvent) => void;
  style?: AnyObject;
};

const Form: FC<Props> = ({
  children,
  classNames = {
    root: '',
  },
  noValidate = false,
  onSubmit,
  style,
}) => (
  <form
    noValidate={noValidate}
    onSubmit={onSubmit}
    className={cn(s.root, classNames.root)}
    style={style}>
    {children}
  </form>
);

export default Form;
