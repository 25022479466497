import { ChangeEvent } from 'react';

export class FormError extends Error {
  message: string;
  code: string;
  fields: string[];

  constructor(message: string, code: string, fields: string | string[]) {
    super(message);

    this.message = message;
    this.code = code;

    if (fields) {
      this.fields = Array.isArray(fields) ? fields : [fields];
    }
  }
}

export interface FormErrorObject {
  code: string;
  message: string;
  fields?: string[];
}

export const clearFormFieldErrors = (
  formErrors: FormErrorObject[] = [],
  field: string,
  includingFields: string[] = []
): FormErrorObject[] =>
  (formErrors || []).filter(
    (e) =>
      e.fields &&
      !(
        e.fields.includes(field) ||
        e.fields.some((f) => includingFields.includes(f))
      )
  );

export const formErrorToJSON = (formError: FormError): FormErrorObject => {
  const obj: FormErrorObject = {
    code: formError.code,
    message: formError.message,
  };

  if (formError.fields) {
    obj.fields = formError.fields;
  }

  return obj;
};

interface OnChangeValue {
  field: string;
  value: string | number | boolean;
}

export const getOnChangeValue = (event: ChangeEvent): OnChangeValue => {
  const target = event.target as HTMLInputElement;

  const { name, type, value, checked } = target;

  let _value: string | number | boolean = value;

  if (type === 'checkbox') {
    _value = checked;
  }

  return {
    field: name,
    value: _value,
  };
};

export const filterGenericErrors = (
  errors: FormErrorObject[],
  includingFields?: string[]
): FormErrorObject[] => {
  if (!errors || !errors.length) return [];

  return errors.filter(
    (err) =>
      !err.fields ||
      (err.fields && err.fields.length === 0) ||
      (err.fields &&
        err.fields.length &&
        includingFields &&
        includingFields.length &&
        err.fields.some((field) => includingFields.includes(field)))
  );
};

export const filterFieldErrors = (
  errors: FormErrorObject[],
  field: string
): FormErrorObject[] => {
  if (!errors || !errors.length) return [];

  return errors.filter(
    (err) => err.fields && err.fields.length && err.fields.includes(field)
  );
};
